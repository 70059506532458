import { animate, scroll } from "motion";


//Animate Timelinecircles
const timelinedots = document.querySelectorAll('.timeline_entry_dot.type--fadein');
timelinedots.forEach((dot) => {
    scroll(animate(dot, { opacity: [0, 0, 1, 1] }), {
        target: dot,
        offset: ["start end", "end end", "center center", "end start"],
        type: "spring",
    })
});

//Animate Timelineyears
const timelineyears = document.querySelectorAll('.timeline_entry_background');
timelineyears.forEach((year) => {
    if (year.parentNode.parentNode.classList.contains('entry--right')) {
        scroll(animate(year, {
            left: ["-10%", "-15%", "-15%"],
            top: ["-50px", "-100px", "-100px"],
            opacity: [0.4, 0.4, 0],
        }), {
            target: year,
            offset: ["-150%", "-50%", "-10%"],
            type: "spring",
        })
    } else {
        scroll(animate(year, {
            right: ["-10%", "-15%", "-15%"],
            top: ["-50px", "-100px", "-100px"],
            opacity: [0.4, 0.4, 0],
        }), {
            target: year,
            offset: ["-150%", "-50%", "-10%"],
            type: "spring",
        })
    }
});

//Animate Timelineyears
const timelineimage = document.querySelectorAll('.timeline_entry_image');
timelineimage.forEach((image) => {
    scroll(animate(image, {
        transform: ["translateY(100px)", "translateY(0px)"],
        opacity: [0, 1],
    }), {
        target: image,
        offset: ["start end", "center center"],
        type: "spring",
    })
});

//Animate Timelineimagedescription
const timelineimgdescription = document.querySelectorAll('.timeline_entry_imagetext');
timelineimgdescription.forEach((imgdesc) => {
    scroll(animate(imgdesc, {
        transform: ["translateY(50px)", "translateY(0px)"],
        opacity: [0, 1],
    }), {
        target: imgdesc,
        offset: ["start end", "center center"],
        type: "spring",
    })
});

//Animate Timelineheadlines
const timelineh2 = document.querySelectorAll('.timeline_entry_title');
timelineh2.forEach((headline) => {
    scroll(animate(headline, {
        transform: ["translateY(30px)", "translateY(0px)"],
        opacity: [0, 1],
    }), {
        target: headline,
        offset: ["start end", "center center"],
        type: "spring",
    })
});


//STORYS
const storysliders = document.querySelectorAll('.animate--rotatefadein');
storysliders.forEach((slider) => {
    scroll(animate(slider, {
        transform: ["rotate(-10deg)", "rotate(-3deg)"],
        opacity: [0, 1],
    }), {
        target: slider,
        offset: ["start end", "center center"],
        type: "spring",
    })
});

const slideins = document.querySelectorAll('.animate--slidein');
slideins.forEach((slidein) => {
    scroll(animate(slidein, {
        transform: ["translateY(100px)", "translateY(0px)"],
        opacity: [0, 1],
    }), {
        target: slidein,
        offset: ["start end", "center center"],
        type: "spring",
    })
});

const zoomins = document.querySelectorAll('.animate--zoomin');
zoomins.forEach((zoomin) => {
    scroll(animate(zoomin, {
        transform: ["scale(1.2)", "scale(1)"],
    }), {
        target: zoomin,
        offset: ["start end", "center center"],
        type: "spring",
    })
});
