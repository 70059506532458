import Swiper from 'swiper/bundle';
import GLightbox from "glightbox";
import { animate, scroll } from "motion";
import "../js/animations.js";

document.addEventListener("DOMContentLoaded", function (event) {

    // INIT MENUBUTTON
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    // INIT SEARCHBUTTON
    const search_button = document.querySelector('[data-behaviour="toggle-search"]');
    if (search_button) {
        const search_input = search_button.parentNode.querySelector('input');
        search_input.tabIndex = -1;
        search_button.addEventListener('click', (event) => {
            if (!document.body.classList.contains('body--search')) {
                event.preventDefault();
                document.body.classList.add('body--search');
                search_input.tabIndex = 0;
                search_button.ariaExpanded = true;
                search_input.focus();
                search_button.ariaLabel = "Suchen";
                return;
            }
        });

        document.addEventListener('click', e => {
            if (!search_button.parentNode.contains(e.target)) {
                document.body.classList.remove('body--search');
                search_input.tabIndex = -1;
                search_button.ariaExpanded = false;
                search_button.ariaLabel = "Suche umschalten";
            }
        });

        //Check if focus is outside the language list
        document.addEventListener('keydown', e => {
            if (e.key === 'Escape') {
                document.body.classList.remove('body--search');
                search_input.tabIndex = -1;
                search_button.ariaExpanded = false;
                search_button.ariaLabel = "Suche umschalten";
            }
        });

        //Check if focus with tab is outside the language list
        document.addEventListener('focusin', e => {
            if (!search_button.parentNode.contains(e.target)) {
                document.body.classList.remove('body--search');
                search_input.tabIndex = -1;
                search_button.ariaExpanded = false;
                search_button.ariaLabel = "Suche umschalten";
            }
        });
    }

    const showHide = document.querySelectorAll('[data-behaviour="show-hide"]');
    showHide.forEach((element) => {
        element.addEventListener('click', () => {
            element.classList.toggle('show-hide--active');
            element.ariaExpanded = !element.ariaExpanded;
        });
    });

    // INIT LIGHTBOX
    const lightboxes = document.querySelectorAll('[data-gallery="gallery"]');

    if (lightboxes.length > 0) {
        lightboxes.forEach((lightbox) => {
            const lightboxselector = lightbox.getAttribute('data-galleryid');
            console.log(lightboxselector);
            lightbox = GLightbox({
                selector: '[data-galleryid="' + lightboxselector + '"]',
                touchNavigation: true,
                loop: true,
            });
        });
    }

    /* const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    }); */

    // INIT SWIPER
    const sliders = document.querySelectorAll('.swiper');
    sliders.forEach(function (slider) {
        const autoSwiper = slider.classList.contains('swiper--auto');
        const swiper = new Swiper(slider, {
            // configure Swiper to use modules
            modules: [Pagination, Navigation, Autoplay, EffectFade],
            effect: 'slide',
            fadeEffect: {
                crossFade: true
            },
            direction: 'vertical',
            loop: true,

            autoplay: autoSwiper ? {
                delay: 5000,
            } : false,

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    });


    const storysliders = document.querySelectorAll('.story-slider');
    if (storysliders.length > 0) {
        storysliders.forEach(function (slider) {
            const pagination = slider.querySelector('.swiper-pagination');
            const next = slider.querySelector('.swiper-button-next');
            const prev = slider.querySelector('.swiper-button-prev');

            new Swiper(slider, {
                loop: true,
                pagination: {
                    el: pagination,
                },
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                },
            });
        });
    }

    const articlesliders = document.querySelectorAll('.article-slider');
    if (articlesliders.length > 0) {
        articlesliders.forEach(function (slider) {
            const pagination = slider.querySelector('.swiper-pagination');
            const next = slider.querySelector('.swiper-button-next');
            const prev = slider.querySelector('.swiper-button-prev');

            new Swiper(slider, {
                loop: true,
                pagination: {
                    el: pagination,
                },
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                },
            });
        });
    }

    // FIXED HEADER
    window.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 30 || document.body.scrollTop > 30){
            document.body.classList.add('menu--fixed');
        } else {
            document.body.classList.remove('menu--fixed');
        }
    });

    const articlepage = document.querySelector('.articlepage');
    if (articlepage) {
        const articlepage_sidebar = articlepage.querySelector('.articlepage_content_sidebar');
        const articlepage_content = articlepage.querySelector('.articlepage_content_elements');

        // Get all the headings in the content and create a list of links in the sidebar
        const headings = articlepage_content.querySelectorAll('h2');
        const sidebar = document.createElement('ul');
        sidebar.classList.add('articlepage_content_sidebar_list');
        headings.forEach(heading => {
            const item = document.createElement('li');
            const link = document.createElement('a');
            link.textContent = heading.textContent;
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const offsetTop = heading.offsetTop - 100;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            });
            item.appendChild(link);

            //Get subheadings
            const headingSibling = heading.nextElementSibling;
            if (headingSibling) {
                const subheadings = headingSibling.querySelectorAll('h3');
                if (subheadings.length > 0) {
                    const sublist = document.createElement('ul');
                    sublist.classList.add('articlepage_content_sidebar_sublist');
                    subheadings.forEach(subheading => {
                        const subitem = document.createElement('li');
                        const sublink = document.createElement('a');
                        sublink.textContent = subheading.textContent;
                        sublink.addEventListener('click', (event) => {
                            //scroll to the subheading
                            event.preventDefault();
                            const offsetTop = subheading.offsetTop - 100;
                            window.scrollTo({
                                top: offsetTop,
                                behavior: 'smooth'
                            });
                        });
                        subitem.appendChild(sublink);
                        sublist.appendChild(subitem);
                    });
                    item.appendChild(sublist);
                }
            }

            sidebar.appendChild(item);
        });
        articlepage_sidebar.appendChild(sidebar);
    }


    // Language Toggle
    const languageToggle = document.querySelector('[data-behaviour="toggle-languages"]');
    const languagelist = document.querySelector('[data-behaviour="language-list"]');

    if(languageToggle && languagelist) {
        languageToggle.addEventListener('click', () => {
            if (!languagelist.classList.contains('language-list--open')) {
                showLanguageMenu(languagelist, languageToggle);
            } else {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });

        //Check if focus is outside the language list
        document.addEventListener('click', e => {
            if (!languageToggle.parentNode.contains(e.target) && !languagelist.contains(e.target)) {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });

        //Check if focus is outside the language list
        document.addEventListener('keydown', e => {
            if (e.key === 'Escape') {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });

        //Check if focus with tab is outside the language list
        document.addEventListener('focusin', e => {
            if (!languageToggle.parentNode.contains(e.target) && !languagelist.contains(e.target)) {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });
    }
    //Timeline
    const timelinetoggles = document.querySelectorAll('[data-behaviour="timeline-toggle"]');
    timelinetoggles.forEach((toggle) => {
        const timelineentrycontent = toggle.parentNode.querySelector('.timeline_entry_content');
        const textmaxheight = timelineentrycontent.scrollHeight;
        toggle.addEventListener('click', () => {
            const timelineentrytext = toggle.parentNode;
            timelineentrytext.classList.toggle('timeline--open');

            if (timelineentrytext.classList.contains('timeline--open')) {
                toggle.textContent = 'Weniger Infos';
                toggle.ariaExpanded = true;
                timelineentrycontent.style.maxHeight = textmaxheight + 'px';
            } else {
                toggle.textContent = 'Mehr Infos';
                toggle.ariaExpanded = false;
                timelineentrycontent.style.maxHeight = '350px';
            }
        });
        toggle.textContent = 'Mehr Infos';
        toggle.ariaExpanded = false;
        timelineentrycontent.style.maxHeight = '350px';
    });


    //Timeline fastnav
    const timelinefastnav = document.querySelector('[data-behaviour="timeline_fastnav"]');
    if (timelinefastnav) {

        //Update the fastnav on scroll if there is an option with the same id.
        var options = timelinefastnav.querySelectorAll('option');
        window.addEventListener('scroll', () => {
            const entries = document.querySelectorAll('.timeline_entry');
            let currententry = entries[0];
            entries.forEach((entry) => {
                if (entry.offsetTop + window.innerHeight < window.scrollY ) {
                    currententry = entry;
                }
            });
            //If options contains the current entry id, update the fastnav
            options.forEach((option) => {
                if (option.value === currententry.id) {
                    timelinefastnav.value = currententry.id;
                } else {
                    //Else use the previous entry
                    //TODO
                }
            });
        });

        timelinefastnav.addEventListener('change', (event) => {
            const target = timelinefastnav.value;
            console.log(target);
            const targetentry = document.getElementById(target);
            const targetoffset = targetentry.offsetTop + window.innerHeight;

            window.scrollTo({
                top: targetoffset + 1,
                behavior: 'smooth'
            });
        });
    }
});

function showLanguageMenu(languagelist, languageToggle)
{
    languagelist.hidden = false;
    languagelist.classList.add('language-list--open');
    languageToggle.ariaExpanded = true;
    languagelist.querySelector('a').focus();
    const links = languagelist.querySelectorAll('a');
    links.forEach((link, index) => {
        link.tabIndex = 0;
    });
}

function hideLanguageMenu(languagelist, languageToggle)
{
    languagelist.hidden = true;
    languagelist.classList.remove('language-list--open');
    languageToggle.ariaExpanded = false;
    const links = languagelist.querySelectorAll('a');
    links.forEach((link, index) => {
        link.tabIndex = -1;
    });
}

window.addEventListener("scroll", function() {
    let scrollPosition = window.scrollY;
    let triggerHeight = 500;

    let link = document.getElementById("to_top_button");
    if (scrollPosition > triggerHeight) {
        link.classList.add('toTopButton--visible');
    } else {
        link.classList.remove('toTopButton--visible');
    }
});

// Scroll to Top Button Functionality
const toTopButton = document.getElementById("to_top_button");
if (toTopButton) {
    toTopButton.addEventListener("click", () => {
        window.scrollTo({
            top: 0
        });
    });
}
